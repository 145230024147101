import "./App.css";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== "https://postmessagepocpopup.web.app") return;
      console.log("message recieved: " + event.data);
    };

    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="App">
      <button
        onClick={() =>
          window.open(
            "https://postmessagepocpopup.web.app",
            "popupWindow",
            "height=600,width=800"
          )
        }
      >
        Popup
      </button>
    </div>
  );
}

export default App;
